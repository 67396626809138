import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroFeaturePage from "../components/pages/shared/hero/feature-page"
import TargetAudienceComponent from "../components/pages/main/target-audience"
import AboutComponent from "../components/pages/main/about"
import ReviewComponent from "../components/pages/main/review"
import FeaturesComponent from "../components/pages/main/features"
import PricingComponent from "../components/pages/main/pricing"
import OfferComponent from "../components/pages/main/offer"
import ContactFormComponent from "../components/pages/shared/contact-form"

const IndexPage = (props) => (
  <Layout page={"index"} lang={"en"}>
    <SEO title="Simple Childcare Management Software"
         keywords={[`childcare management software`, `customer data management`]} url={"https://paraplancrm.ru" + props.location.pathname}
    />
    <HeroFeaturePage
      name={"index"}
      title='Simple Childcare Management Software'
      description='Easily manage teachers, students and classrooms. Track attendance, payments and more.'
      tryNowCaption={"Get started"}
      color='#6D20D2'
      image={require("../images/cover/main.png")}/>
    <TargetAudienceComponent
      title={"Paraplan is built for…"}
      childCare={"Daycare centres"}
      languageSchool={"Language schools"}
      kindergarden={"Preschool care"}
      sportSchool={"Sport clubs"}/>
    <AboutComponent
      title='Manage everything in one place.'
      description="Paraplan was built specifically with children's activities in mind. Easily organise student information, track attendance on-the-go, manage classes, print forms and stay in touch with families via email and SMS integrations."
      image={require("../images/screenshot/main_01@2x.en.png")}
      imageWidth={604}
      imageHeight={376}
      color='#E65D49'/>
    <ReviewComponent author='Savr Muchkaev, Daycare Centre Manager'>
      I like that with Paraplan we can register students online. I spend less time on creating timetables, billing and
      administrative work and I don’t get bogged down with Excel documents to manage it all. Paraplan has freed up time
      to spend on each child and their care.
    </ReviewComponent>
    <FeaturesComponent title='Grow your business with Paraplan'/>
    <PricingComponent title='Pricing'/>
    <OfferComponent
      title={"Sign up Free!"}
      condition={"Explore Paraplan's full range of features"}
      subcondition={"with a 10-day free trial. Upgrade anytime."}
      subconditionSmall={true}
      tryNowCaption={"Try for free"}/>
    <ContactFormComponent/>
  </Layout>
)

export default IndexPage
